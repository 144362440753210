.people{
    padding: 50px 130px;
    margin-bottom: 50px;
}
.people_body .title{
    text-transform: capitalize;
    font-weight: 100;
}
.viewprofile a{
    text-transform: uppercase;
    color: #006AC7;
    font-size: 14px;
    font-weight: 700;
}
.count{
    width: fit-content;
    display: flex;
    margin: 10px auto;
    padding: 0px;;
}
.count li{
    list-style-type: none;
    padding: 10px;
    border: 1px solid #2c2c2c;
    margin: 10px 5px;
    font-size: 25px;
    text-align: center;
}

.globel_search{
    padding: 25px !important;
}


@media only screen and (max-width: 767px){
    .people{
        padding: 50px 0px;
    }
}

.Link{
    text-decoration: underline;
    color: #0d6efd;
    cursor: pointer;
}