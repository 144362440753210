.mob_banner  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -40px;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: unset;
    margin-left: 15%;
    list-style: none;
}
.mob_banner  .carousel-indicators button{
    width: 9px !important;
    height: 9px !important;
    border: 1px solid #0D697D;
}
.carsole_banner_img_left{
    width: 27px;
}
.carsole_banner_img_right{
    width: 27px;
    transform: rotate(180deg);
}
.carousel-control-prev{
    position: absolute !important;
    justify-content: unset !important;
    top: unset !important;
    bottom: -46px !important;
    left: 25% !important;
}
.carousel-control-next{
    position: absolute !important;
    justify-content: unset !important;
    top: unset !important;
    bottom: -46px !important;
    right: 14% !important;
}
.mobile_menu div{
    height: 3px;
    width: 25px;
    background-color: #0d697d;
    margin-bottom: 4px;
    border-radius: 20px;
    cursor: pointer;
}
.login_icn{
    position: absolute;
    top: 21px;
    right: 72px;
    font-size: 20px !important;
    color: #0d697d;
    cursor: pointer;
}
.event_calender{
    position: absolute;
    top: 79px;
    right: 0px;
    color: #0D697D;
    width: fit-content;
    font-weight: 600;
    font-size: 30px;
    word-break: break-word;
    padding: 0px 117px;
    line-height: 35px;
}

.mob_login_icn{
    right: 210px;
}
.mobShow {
    display: none;
}

/* ================= Tooltip start =================  */

.tooltip-body {
    border-radius: 3px;
    padding-left: -2px;
    padding-right: 0.2em;
  }
  .tooltip-body:before {
    font-family: "FontAwesome";
    content: "\f023";  /* padlock */
    padding: 0px 5px;
  }
  .tooltip:hover:after {
    content: "Visible only to staff members!";
    font-size:0.9em;
    padding:0 10px;
    background:black;
  }
  .tooltip-data:hover:after {
    content: attr(data-msg);
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    background: black;
    border-radius: 3px;
    position: absolute;
    top: 18px;
    right: 114px;
  }
  /* ================= Tooltip end =================  */

@media only screen and (max-width: 767px){
    .mob_login_icn{
        right: 65px !important;
        padding: 8px 0px;
        top: 21px !important;
    }
    .login_icn{
        top: 28px;
    }
    .mobShow {
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px){
    .event_calender{
        padding: 0px;
    }
}
