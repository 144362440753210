.footer {
    background: rgb(204, 244, 250);
    background: linear-gradient(90deg, rgba(204, 244, 250, 1) 0%, rgba(229, 253, 218, 1) 100%);
    padding: 50px 150px 40px;
    position: relative;
    margin-bottom: 66px;
}

.center {
    background-color: #0D697D;
    padding: 15px 30px;
    border-radius: 5px;
    position: absolute;
    bottom: 110px;
    left: 0px;
    width: -webkit-fill-available;
    width: -moz-available;
    margin: auto 150px;
}

.center .curvedarrow {
    width: 70%;
}

.center h4 {
    color: #fff;
    margin: 0px;
    font-weight: 700;
    font-size: 23px;
    line-height: 37px;
    margin-top: 13px;
}

.btn_group {
    position: relative;
    padding: 35px 0px;
    width: fit-content;
    margin: auto;
}

.btn_group .btn_decorator1 {
    width: 60px;
    position: absolute;
    top: 0;
    left: 30%;
}

.btn_group .btn_decorator2 {
    width: 60px;
    position: absolute;
    bottom: 6px;
    left: 30%;
    transform: rotate(180deg);
}

.btn_group button {
    padding: 7px 20px;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border: unset;
    border-radius: 3px;
    color: #0D697D;
    font-size: 14px;
}

.flex_grow1 {
    flex-grow: 1;
}

.copy_right {
    display: flex;
}

.copy_right .left {
    display: flex;
}

.copy_right .left .logo_txt {
    color: #12C28A;
    font-weight: 700;
    font-size: 20px;
    line-height: 47px;
    padding: 0px 10px;
}

.copy_right .left .copy_right_txt {
    color: #0F2137;
    opacity: 0.6;
    font-weight: 400;
    font-size: 14px;
    line-height: 47px;
}

.copy_right_txt {
    color: #0F2137;
    opacity: 0.6;
}

.copy_right .left img {
    width: 40px;
    height: 44px;

}

.copy_right .right ul {
    display: flex;
    justify-content: space-between;
}


.copy_right .right {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 0px 15px 0px;
    background-color: #ffffff;
    z-index: 998;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.copy_right .right ul {
    margin: auto;
    padding: 0px;
}

.copy_right .right ul li {
    display: grid;
    width: 100px;
    line-height: 30px;
    font-size: 12px;
}

.copy_right .right ul li a {
    color: unset;
    text-decoration: unset;
}

.copy_right .right ul li i {
    font-size: 21px;
    width: 37px;
    height: 37px;
    border-radius: 35px;
    margin: auto;
    color: #0d697d;
    padding: 12px 5px;
    text-align: center;
}

.copy_right .right ul li span {
    font-size: 12px;
    line-height: 15px;
    display: block;
    margin-bottom: 10px;
}

.copy_right .right ul li:nth-child(1) i {
    font-size: 16px;
}

.copy_right .right ul li {
    list-style-type: none;
    font-size: 14px;
    padding: 0px 10px;
    cursor: pointer;
    line-height: 47px;
    color: #0d697d !important;
    text-align: center;
    font-weight: 600;
}

@media only screen and (max-width: 767px) {
    .copy_right .flex_grow1 {
        flex-grow: unset;
    }

    .copy_right .left {
        width: fit-content;
        margin: auto;
    }

    .copy_right {
        display: unset;
    }

    .curvedarrowbody {
        display: none;
    }

    .center {
        left: 0px;
        width: -webkit-fill-available;
        text-align: center;
        padding: 10px 20px;
        bottom: 185px;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: auto;
    }

    .btn_group {
        width: fit-content;
        margin: 17px auto 0px;
    }

    .btn_group .btn_decorator1,
    .btn_group .btn_decorator2 {
        left: 35%;
        ;
    }

    .footer {
        margin: 180px 0 74px;
        padding: 50px 0px;
        text-align: center;
        margin-top: 150px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1280px) {
    .footer {
        padding: 75px 115px 20px 115px;
    }

    .footer {
        padding: 25px 20px 7px 20px;
    }

    .curvedarrowbody {
        width: fit-content !important;
        margin: auto;
    }

    .curvedarrowbody img {
        width: 200px !important;
        display: none;
    }

    .center {
        left: 0;
        bottom: 77px;
        width: 100%;
        margin: auto;
    }

    .btn_group {
        width: fit-content !important;
        margin: auto;
    }

    .btn_group .btn_decorator1,
    .btn_group .btn_decorator2 {
        left: 33%;
    }
}