.what_we_Do h5 {
    font-size: 20px;
}

.challenge .title {
    font-size: 22.5px;
}

.big_challenge .title {
    color: #0d697d;
    font-size: 22.5px;
    line-height: 40px;
    font-weight: 400;
}

.big_challenge .big_change {
    color: #0d697d;
    font-size: 34px;
    font-weight: 700;
}

.big_challenge .content {
    font-size: 16px;
}

.big_challenge .title{
    font-size: 22.5px;
}

.big_card img {
    height: 35px;
    width: 41px;
}

.big_card .title {
    color: #000;
    margin-top: 13px;
    font-weight: 400;
    font-size: 18px;
    line-height: unset;
    word-break: break-word;
}

.title {
    font-size: 45px;
    color: #0d697d;
    font-weight: 600;
    line-height: 45px;
}

.subtitle {
    font-size: 50px;
    color: #0d697d;
}

.card-img-top {
    object-fit: cover;
    object-position: 50% 0%;
}

.spo_first_child {
    margin-top: 60px !important;
}

.spo_card {
    padding: 10px;
    border-radius: 11px;
    margin: 0px 0px 30px 0px;
    background-color: #fff;
    box-shadow: 0px 25px 100px rgb(69 88 157 / 8%);
}

.spo_card .title {
    color: #28D1DC;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
    line-height: 27px;
    margin: 40px 0px 40px;
}

.spo_card p {
    text-align: center;
    font-size: 15px;
    margin: 17px 23px;
}

.spo_card button {
    border: 1px solid #28D1DC;
    color: #28D1DC;
    padding: 7px 20px;
    border-radius: 20px;
    background-color: transparent;
    margin-bottom: 40px;
}

.oursponsors {
    padding: 75px 150px 90px;
    background-color: #F6F7F9;
}

.fnt_16 {
    font-size: 16px;
}

.waica {
    height: 150px;
    object-fit: contain;
    padding: 0px 0px 30px 0px;
}

.carsole_img {
    margin: auto;
    height: 590px;
    border-radius: 10px;
    object-fit: cover;
}

.carousel-indicators [data-bs-target] {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50%;
}

.changeismadeby {
    padding: 0px 150px 90px;
}

.changeismadeby_card {
    padding: 0px 30px 30px;
    border-radius: 10px;
    position: relative;
}
.changeismadeby_card img{
    width: 70px;
    height: auto;
}

.changeismadeby_card .synbol {
    display: flex;
}

.changeismadeby_card .synbol div {
    height: 52px;
    width: 23px;
    border-radius: 5px;
    margin: 0px 5px;
    opacity: 0.21;
    margin-top: 10px;
}

.changeismadeby_card .synbol div:nth-child(2) {
    height: 65px;
    opacity: 1;
    margin-top: unset;
}

.changeismadeby_card .title {
    font-size: 18px;
    color: #343D48;
    margin: 15px 0px 5px;
    text-align: center;
    line-height: unset;
}

.changeismadeby_card .content {
    font-size: 16px;
    text-align: center;
}

.blog {
    background-color: #F6F7F9;
    margin: 0px 5px;
    padding: 75px 150px;
}

.title-big {
    font-size: 45px;
    color: #0d697d;
}

.start i {
    color: #EA3A60;
    margin: 0px 3px;
    font-size: 17px;
}

.start i:last-child {
    color: #E4E4E4;
}

.blog .card {
    background-color: #fff;
    padding: 25px;
    border: unset;
    box-shadow: 0px 6px 47px rgb(38 78 118 / 10%);
    text-align: left;
}

.blog .title {
    color: #343D48;
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0px;
    line-height: unset;
}

.blog .content {
    margin-bottom: 30px;
}

.blog .profile .logo img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.blog .profile {
    display: flex;
}

.blog .profile .content {
    margin: 15px;
}

.blog .profile .content .name {
    font-size: 15px;
    font-weight: 600;
}

.blog .profile .content .mail {
    color: red;
    font-size: 14px;
}

.happy_customers {
    padding: 75px 150px 90px;
}

.happy_customers img {
    height: 500px;
    object-fit: cover;
    object-position: 50% 5%;
}

.what_we_Do {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: unset !important;
    background-clip: border-box;
    border-radius: 0.25rem;
    box-shadow: #f7f7f7db 0px 5px 16px 1px !important;
    border: unset !important;
    margin: 45px 0px 3px;
}

.omni_calc .calc {
    width: fit-content;
    margin: 53px auto;
}

.impact_card {
    background-color: #ececec;
    padding: 10px;
    margin: 30px 0px 0px;
    text-align: center;
    box-shadow: 0px 25px 100px rgb(177 170 170 / 24%);
}

.impact_card .upto {
    font-weight: 700;
    margin: 0px;
}

.impact_card .number {
    color: #EB0C00;
    font-size: 40px;
    font-weight: 300;
    letter-spacing: normal;
    text-transform: lowercase;
    margin-bottom: 1px;
}

.impact_card .content {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: normal;
    margin: 0px;
}

.impact_card .img_set {
    width: fit-content;
    margin: auto;
}

.impact_card .img_set img {
    width: 70px;
    height: auto;
    margin: 30px 0px 8px;
}

.omni_calc {
    max-height: 700px;
    min-height: 1000px;
    width: 540px;
    margin: auto;
    display: block;
}

.plafera_impact {
    padding: 0px 150px;
}

.footer_sponsor {
    display: flex;
}

.footer_sponsor p {
    margin: 14px 15px;
}

.footer_sponsor img {
    width: 100px;
}

.mob_view {
    display: none;
}

.web_view {
    display: block;
}


@media only screen and (max-width: 767px) {
    .footer_sponsor {
        width: fit-content;
        margin: 14px auto;
    }
    .footer_sponsor{
        display: block !important;
    }

    .footer_sponsor img {
        width: 100px;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;
    }

    .big_challenge {
        text-align: center;
        padding: 40px 0px 60px;
    }

    .mob_view {
        display: block;
    }

    .web_view {
        display: none;
    }

    .carsole_img {
        width: 100% !important;
        margin-top: 50px;
    }

    .oursponsors,
    .changeismadeby,
    .blog,
    .happy_customers {
        padding: 30px 0px;
        text-align: center;
    }

    .mrg_l_60 {
        margin-left: unset;
    }

    .changeismadeby .title {
        margin-top: unset;
    }

    #slider-container {
        padding: 20px 0px 70px;
    }

    /* .spo_card{
        margin-top: 0px !important;
    } */
    .title {
        font-size: 40px;
    }

    .changeismadeby_card {
        margin: 30px 30px;
    }

    .mob_res_hide {
        display: none;
    }

    .what_we_Do {
        margin: 20px 0px;
    }

    .plafera_impact {
        padding: 30px 0px;
    }
    .plafera_impact .title{
        text-align: center;
    }
    .omni_calc {
        width: 100%;
    }

    .swiper-slide {
        width: 100% !important;
        margin-right: unset !important;
    }

    .footer_sponsor p {
        margin: unset;
        word-break: break-all;
        white-space: nowrap;
    }
    .popup_content{
        width: 90% !important;
    }
    .mob_mrg_t_70{
        margin-top: 70px;
    }
    .pie_chart .title{
        text-align: center;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1280px) {

    .challenge,
    .oursponsors,
    .changeismadeby,
    .blog,
    .happy_customers {
        padding: 90px 0px;
    }

    .big_challenge {
        padding: 40px 0px;
    }

    .tab_wid_100 {
        width: 100% !important;
        text-align: center;
    }
    .plafera_impact {
        padding: 30px 0px;
    }
}

.popup_body{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999999;
}
.popup_content{
    width: 700px;
    background-color: #fff;
    border-radius: 3px;
    margin: 10% auto;
    padding: 20px;
    box-shadow: #000000b0 0px 0px 14px 1px;
    position: relative;
}
.payment_btn button{
    background-color: rgb(13, 105, 125);
    padding: 8px 25px;
    color: #fff;
    margin: 5px;
    border-radius: 37px;
    border: unset;
    font-size: 16px;
    width: 150px;
}
.payment_subtitle{
    font-size: 20px;
    color: #056c3f;
    text-align: center;
    margin: 10px 0px;
}
.payment_title{
    font-size: 30px;
    color: #0D697D;
    text-align: center;
}
.popup_close{
    height: 30px;
    width: 30px;
    background-color: #dc3545;
    text-align: center;
    padding: 3px 0px;
    border-radius: 30px;
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: -16px;
    color: #fff; 
}
.no_data{
    text-align: center;
    font-size: 22.5px;
    padding: 30px;
    background-color: #f3f3f3;
    margin: 15px 0px;
}
.result{
    position: absolute;
    bottom: -39px;
    left: 39.9%;
    height: 70px;
    width: 70px;
    background-color: #0d697d;
    border-radius: 50px;
    color: white;
    padding: 10px 26px;
    font-size: 30px !important;
    font-weight: 700 !important;
    text-align: center !important;
    box-shadow: #0000003d 0px 11px 19px;
    font-style: italic;
}
.winner_card{
    padding: 45px 25px 58px 15px;
    margin: 10px 0px 30px;
    border-radius: 3px;
    position: relative;
    border-bottom: 4px solid #0d697d;
    box-shadow: #b3afaf 0px 0px 12px 1px;
}
.winner_card p{
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    margin: 0px;
}
.winner_card .small_txt{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #6c6c6c;
    margin: 0px;
}