.cam_btn{
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 15px;
    margin-top: 25px;
    color: #ffffff;
    border-radius: 4px;
    background-color: #6F6AB9;
    transition: color 0.2s ease, background-color 0.2s ease;
    width: unset;
    border: unset;
}

.cam_flat_btn{
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 15px;
    margin-top: 25px;
    color: #ffffff;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #fff;
    transition: color 0.2s ease, background-color 0.2s ease;
    width: unset;
    text-align: center;
}

.campus_header{
}
.campus_header .navidate_url{
    text-decoration: unset;
    font-size: 18px;
    margin-bottom: 24px;
}
.campus_header .title{
    font-weight: 400;
}
.menu_group{
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    padding: 30px 20px;
}
.cus_droupdown, .menu{
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    margin: 5px 15px;
    cursor: pointer;
    padding: 0px 25px;
    text-transform: uppercase;
    font-weight: 400;
}
.cus_droupdown_content{
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #fff;
    box-shadow: #ddd 0px 0px 5px 1px;
    z-index: 9;
}
.cus_droupdown_content ul{
    padding: 12px 0px;
    margin: 0px;
}
.cus_droupdown_content ul li{
    list-style-type: none;
    padding: 7px 25px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
}
.cus_droupdown i{
    position: absolute;
    top: 6px;
    right: 0;
}

.campus_banner {
    position: relative;
    min-height: 410px;
    margin: 0;
    text-align: center;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../public/assets/images/Campus_Cover.jpg');
    padding: 150px 15px;
}
.campus_banner .content{

    position: relative;
    max-width: 390px;
    padding: 55px 15px 10px;
    margin: 0 auto;
    text-align: center;   
    background: linear-gradient(105.18deg, #ccf4fcd6 -2.04%, #eaffd5d1 107.42%);
}
.campus_banner .content .title{
    padding: 25px;
    color: #0d697d;
    letter-spacing: 0;
    text-transform: capitalize;
    font-size: 2.5em;
    line-height: normal;
    font-weight: unset;
}
.campus_banner .content .sub_title{
    padding: 15px;
    letter-spacing: 0;
    font-size: 16px !important;
    color: #2c2c2c;
}
.cat_card{
    background-color: #33B48A;
    text-align: center;
    padding: 50px 0px;
}
.cat_card .logo{

}
.cat_card .logo img{
    width: 50px;
    height: 50px;
}
.cat_card .title{
    font-weight: 400;
    font-size: 20px;
    text-transform: lowercase;
    letter-spacing: 0;
    color: #231F20;
}
.cat_card .sub_title{
    font-weight: 700;
    font-size: 11px;
    text-transform: lowercase;
    letter-spacing: 0;
    color: #231F20;
}
.category{
    padding: 70px 0px;
    background-color: #1C6987;
}
.category .title{
    color: #fff;
    font-weight: 200;
    text-transform: capitalize;
}


/* action categories start */
.action_categories{
    padding: 70px 0px;
    background-color: #40434F;
}
/* action categories end */

/* action by action categories start */
.action_categories_body{
    padding: 50px 0px;
}
.Action_card{
    position: relative;
    border-top: 7px solid #2c2c2c;
    box-shadow: 0 0 5px 1px #00000042;
}
.Action_card .title{
    font-size: 15px;
    text-transform: uppercase;
}
.Action_card .sub_title{
    color: #2c2c2c;
}
.Action_card .Actions{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
.already_did_this{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/* action by action categories end */