.dash-header {
    background: rgb(204, 244, 250);
    background: linear-gradient(90deg, rgba(204, 244, 250, 1) 0%, rgba(229, 253, 218, 1) 100%);
    position: relative;
}

.document-container {
    background: #FFF2E9;
    padding: 1rem;
}

.document-icon {
    color: #EB6200 !important;
}

.total-points-container {
    background: #EDE8FF;
    padding: 1rem;
}

.total-points-icon {
    color: #551FFF !important;
}

.team-container {
    background: #EAF9FF;
    padding: 1rem;
}

.team-icon {
    color: #00B7FE !important;
}

.team-points-container {
    background: #FFEBEF;
    padding: 1rem;
}

.team-points-icon {
   color: #FD2254 !important;
}

.cus_file_body label{
    padding: 11px 0px;
    background: #0D697D;
    display: table;
    color: #fff;
    margin: auto;
    border-radius: 50px;
    height: 45px;
    width: 45px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}
.cus_file {
    display: none !important;
}
.actionLearnMoreModel_Body{
    position: absolute;
    top: 38px;
    left: -7px;
    background-color: #fff;
    padding: 5px;
    font-size: 13px;
    box-shadow: #00000054 0px 0px 4px 1px;
    border-radius: 3px;
}
.actionLearnMoreModel_Body ul{
    padding: 0px;
    margin: 0px;
}
.actionLearnMoreModel_Body ul li{
    list-style-type: none;
    padding: 5px 0px;
}
.actionLearnMoreModel_Body ul li:hover{
    background-color: #cccccc78;
}
.actionLearnMoreModel_Body ul li a{
    margin: 4px 12px;
    width: inherit;
    line-height: 30px;
    text-decoration: none;
}