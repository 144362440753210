.T_profile_body{
    margin: 2% 15%;
    padding-bottom: 45px;
}
.T_actions{
    padding: 2% 15%;
    padding-bottom: 45px;
    background-color: #FAFAFA;
}
.T_profile_content .header {
    background-image: url("https://cdn.pixabay.com/photo/2022/02/18/07/27/lake-7020123_960_720.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    background-position: 50% 50%;
    padding: 90px 20px;
}
.T_profile_content .header::before{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(36,37,38,0.65);
    content: '';
}
.T_profile img{
    height: 100px;
    width: 100px;
 }
 .T_profile_content .header .title{
    color: #ffffff;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 100;
 }
 .T_profile_content .header i{
    font-size: 18px;
    color: #ffffff;
    margin: 0px 10px;
 }
 .points_body .box{
    padding: 30px;
    border-right: 1px solid #cccccc;
 }
 .team_fead_card{
    background-color: #fff;
    padding: 25px 75px 25px 50px;
    margin-bottom: 10px;
    position: relative;
 }
.team_fead_card .team_points{
    position: absolute;
    top: 24px;
    right: 7px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    padding: 9px;
    background-color: #0d697d;
}
.team_fead_card .team_circle{
    height: 30px;
    width: 30px;
    background-color: #ffc107;
    border-radius: 30px;
    position: absolute;
    top: 25px;
    left: 8px;
    text-align: center;
    padding: 8px 0px;
    font-size: 10px;
    letter-spacing: -1px;
    text-transform: uppercase;
}
 @media only screen and (max-width: 767px){
    .T_profile_body{
        margin: 2% 15px;
    }
    .T_actions{
        padding: 2% 15px;
    }
 }