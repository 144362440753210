.das_nav_header {
    display: flex;
    padding: 7px 20px 7px 225px;
    background-color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 999;
    box-shadow: 0px 10px 30px rgb(17 38 146 / 3%);
}

.flex-grow {
    flex-grow: 1;
}

.search {
    position: relative;
    margin: 12px 0px;
}

.search input {
    border: 1px solid #A9E1ED;
    text-indent: 24px;
}

.search i {
    color: #ADB5BD;
    position: absolute;
    top: 6px;
    left: 4px;
    font-weight: 300;
}

.profile {
    display: flex;
}

.profile img {
    width: 40px;
    margin: 2px 10px;
}

.profile h4 {
    font-size: 16px;
    color: #232D42;
    margin: 0px;
}

.profile p {
    font-size: 13px;
    color: #8A92A6;
    margin: 0px;
}

.profile div {
    margin: 6px 5px 0px 6px;
}

.content_group {
    display: flex;
}

.content_group .country {
    width: 18px;
    height: auto;
    line-height: 10px;
    margin: 8px;
}

.content_group .notifig {
    width: 18px;
    height: auto;
    line-height: 10px;
    margin: 8px;
}

.content_group .mail {
    width: 18px;
    height: auto;
    line-height: 10px;
    margin: 8px;
}
.sidemenu{
    position: fixed;
    top: 79px;
    left: 0px;
    width: 200px;
    background-color: #fff;
    z-index: 999;
    height: 90%;
    box-shadow: 0px 10px 30px rgb(17 38 146 / 5%);
    overflow-y: scroll;
}

.sidemenu::-webkit-scrollbar {
    display: none;
}
.das_nav_header .logo{
    padding: 5px 27px;
    display: flex;
    line-height: 54px;
}
.das_nav_header .logo img{
    width: 50px;
    height: 50px;
}
.das_nav_header .logo i{
    background-color: #0D697D;
    color: #fff;
    padding: 8px 0px;
    font-size: 15px;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    text-align: center;
    margin: 10px 18px;
    display: none;
}
.menulist ul{
    padding: 0px;
    display: flex;
    margin: 10px 0px;
}
.menulist ul li{
    list-style-type: none;
    color: #8A92A6;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    position: relative;
    padding: 7px 25px;
}
.menulist ul li img{
    width: 18px;
    margin-right: 10px;
}
.menulist ul li .droup_down_icn{
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 18px;
}
.menulist .droupdown_body{
    position: absolute;
    top: 25px;
    left: 0;
    display: block;
    background-color: #fff;
    box-shadow: #dddddd8a 0px 0px 5px 1px;
}
.content_view{
    background-color: #E5E5E5;
    width: 100%;
    z-index: 9;
    padding: 77px 0px 0px 75px;
}
.das_footer{
    padding: 0px 15px 0px 225px;
    background-color: #fff;
    display: flex;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
}
.das_footer p{
    font-size: 14px;
    color: #000000;
    margin: 0px;
    line-height: 35px;
}
.das_footer .logo{
    font-size: 14px;
}
.das_footer .logo img{
    width: 40px;
    height: 40px;
}
.das_footer .copy_right{
    margin-left: 10px;
}

.logout_icn {
    color: #8a92a6;
    font-size: 20px;
    width: 32px;
    margin: 21px 5px;
    cursor: pointer;
}
.humbergermenu{
    margin: 21px 5px;
    color: #817676;
    font-size: 20px !important;
    display: none !important;
}



@media only screen and (max-width: 767px){

    
    .humbergermenu{
        display: block !important;
    }
    .webMenu{
        display: none;
    }
    .content_view{
        padding: 66px 0px 0px 0px !important;
        height: calc(100vh - 97px);
        overflow-y: auto;
    }
    .menulist{
        position: absolute;
        background-color: #fff;
        box-shadow: #ddd 0px 2px 4px 1px;
        width: 100%;
        left: 0;
        top: 82px;
        /* padding-bottom: 70px; */
    }
    .menulist ul{
        display: block;
        width: 100%;
    }
    .das_nav_header .logo i{
        display: block;
    }
    .sidemenu{
        display: none;
    }
    .das_nav_header .logo{
        padding: 5px 0px;
    }
    .das_footer{
        padding: 5px 20px 5px 20px !important;
        box-shadow: #ddd 0px 0px 4px 1px;
        display: unset;
        text-align: center;
    }
    .das_footer p{
        line-height: 16px;
    }
    .profile h4, .profile p{
        display: none;
    }
    .menulist .droupdown_body{
        position: relative;
        top: 0px;
        left: 0px;
    }
    .mob_dis_block{
        display: block !important;
    }
}

.dashboard_round{

    height: 80px;
    width: 80px;
}
.pad_20px{
    padding: 5px 20px !important;
}
.ActiveMyChallenge{
    background-color: #198754;
    color: #fff !important;
}