/* ============== common css start ==============  */
html{
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family:  'Segoe UI', 'Open Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

img {
  width: 100%;
}
.astric{
  color: #dc3545;
  font-size: 6px !important;
}
.overflow_x_hide{
  overflow-x: hidden;
}
.float_right{
  float: right;
}

.DataNotThere{
  color: #6c757d;
  padding: 24px;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: #ddd 0px 0px 14px 1px;
  margin-bottom: 60px;
  font-style: italic;
  border-radius: 5px;
}

.fnt_22_5px{
  font-size: 22.5px !important;
}
.fnt_34px{
  font-size: 34px !important;
}
/* ==========================Margins=========================== */
.mt-n1 {
  margin-top: -1rem !important;
}

.mt-n2 {
  margin-top: -2rem !important;
}
.mt-n3 {
  margin-top: -3rem !important;
}

@media only screen and (min-width: 768px) {
  .mt-n4 {
    margin-top: -4rem !important;
  }
}

.flex_grow-1 {
  flex-grow: 1;
}
.theme_btn {
  background-color: rgb(13, 105, 125);
  padding: 8px 25px;
  color: #fff;
  margin: 5px;
  border-radius: 37px;
  border: unset;
  font-size: 16px;
  width: 150px;
  white-space: nowrap;
}
.theme_btn_atag{
  text-decoration: unset !important;
}
.theme_btn_atag:hover{
  color: #ffffff !important;
}

.flat_btn {
  background-color: unset;
  padding: 5px 25px;
  color: #0d697d;
  margin: 13px 0px;
  border-radius: 20px;
  border: unset;
  display: flex;
  justify-content: center;
  line-height: 29px;
  white-space: nowrap;
}
.flat_btn  i{
  font-size: 28px;
    margin-right: 8px;
}
.wid_fit_content{
  width: fit-content !important;
}
.pos_rel{
  position: relative;
}
.mrg_t_140 {
  margin-top: 140px;
}

.mrg_t_60 {
  margin-top: 60px !important;
}

.mrg_t_20 {
  margin-top: 20px;
}

.mrg_l_20 {
  margin-left: 20px;
}

.mrg_l_60 {
  margin-left: 60px;
}

.mrg_r_60 {
  margin-right: 60px;
}

.mar_auto {
  margin: auto;
  width: fit-content;
}

.flex_grow{
  flex-grow: 1;
}
.pad_0_imp{
  padding: 0px !important;
}
.pad_lr_30px{
  padding: 0px 30px;
}
.pad_lr_15px{
  padding: 0px 15px;
}
.working_in_progress{
  width: 60%;
  margin: auto;
}
.working_in_progress img{
  width: 100%;
  height: auto;
}
.span_error{
  color: #dc3545;
}
p{
  color: #343D48;
  font-size: 14px;
}

.border-pla-primary {
  border: 1px solid #0d697d !important;
}

.border-none {
  border: none !important
}

.border-none:focus {
  outline: none !important;
}

.sub-title-login {
  color: #0d697d;
}

.how-image {
  height: 180px;
}

.flex-1 {
  flex: 1;
}

.fs-sm {
  font-size: small;
}
.animate-ease {
  transform: scale(.9,.9);
}
.animate-ease:hover {
  transform: scale(1,1);
  transition: .2s ease-in-out;
}

@media only screen and (min-width: 768px) {
  .how-image {
    height: 200px;
  }
}


/* ============== common css end ==============  */

/* ============== colors start ==============  */

.text-pla-primary {
  color: #0d697d !important;
}

.text-pla-gray {
  color: #8A92A6 !important;
}

.text-pla-success {
  color: #11C478 !important;
}

.bg-purple {
  background: #DE69C5;
}

.bg-purple-100 {
  background: #DEA3D1;
}

.bg-purple-100:hover {
  background: white;
}

.bg-pla-primary {
  background: #0d697d !important;
}

.bg-pla-success {
  background: #11C478 !important;
}

.bg-pla-gray {
  background: #E5E5E5 !important;
}

.bg-dark-80 {
  background: #00000041 !important;
}

.fw-bolderer {
  font-weight: 950 !important;
}

.fs-7 {
  font-size: 70px;
  color: #DDDDDD;
}
/* ============== Forms Start ==============  */

.form-select, .form-control {
  border: 1px solid #0D697D !important;
}
.form-select:focus, .form-control:focus {
  box-shadow: none !important;
}

.react-select-container {
  border-radius: 5px;
  border: 1px solid #0D697D !important;
 
}


label {
  color: #8A92A6;
}

@media only screen and (min-width: 768px) {
  .auth-form {
    width: 50% !important;
  }

  .team-form {
    width: 75% !important;
  }
}


/* ============== Forms end ==============  */


/* ============== colors end ==============  */

/* ============== Global Sizing ================== */

.w-md-50 {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.hover-underline:hover {
  text-decoration: underline;
}
/* ============== slim scroll start ============== */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #0D697D; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0D697D; 
}
/* ============== slim scroll end ============== */

.wid-50{
  width: 50%;
}

@media only screen and (max-width: 767px){
  .wid-50{
    width: 100% !important;
  }
  .mrg_t_140{
    margin-top: 0px;
  }
  .mrg_l_20{
    margin-left: 0px;
  }
  .mrg_r_60{
    margin-right: 0px;
  }
  .mrg_t_60{
    margin-top: 0px !important;
  }
  .wid_100per{
    width: 100% !important;
  }
  .float_right{
    float: unset;
  }
  .reply_body{
    padding: 10px 15px !important;
    margin: 0px 0px !important;
  }
} 


.ath-prompt-logo{
  height: 100px;
  width: 100px;
  border: unset;
  
}
.omni-calculator{
  width: 100% !important;
}
.height_150{
  height: 150px !important;
}

/* ============== feeds start ============== */

.reply_body{
  padding: 10px 50px;
  /* box-shadow: #ddd 0px 0px 6px 1px; */
  margin: 0px 60px;
}
.feed_replay_msg{
  font-size: 13px;
  color: #ffffff;
  margin: 0px 11px;
  background-color: #6c757d;
  padding: 10px;
  border-radius: 20px;
}
/* ============== feeds end ============= */

.feedcircle{
  height: 50px;
  width: 50px;
  padding: 15px 20px !important;
  text-align: center;
  border-radius: 50px;
}

@media only screen and (min-width: 767px) and (max-width: 1280px) {

  .tab_wid_100 {
      width: 100% !important;
      text-align: center;
  }
  .menulist ul li{
    padding: 7px 9px !important;
  }
  .profile{
    display: none !important;
  }
  .tab_pad_lr_27{
    padding: 7px 27px !important;
  }
}

/* about Page start */
.contact_icn{
  font-size: 20px !important;
  margin: 0px 12px 0px 0px;
}
.fit_card_img{
  height: 350px;
  object-fit: cover;
}
/* about page end */

/* blog section start */
.Blog_Body{
  box-shadow: #ddd 0px 0px 14px 1px;
  cursor: pointer;
}
.Blog_Body .title{
  font-size: 18px;
  margin-bottom: 10px;
}
.Blog_Body .content{

}
.Blog_Body .tags{

}
.Blog_Body .banner_img{
  margin: 10px 0px 25px;
}
.palfera-challenge-slider .swiper{
  padding: unset;
}
/* blog section end */

.sign-up-confirmation-message, .sign-up-confirmation-message p {
  font-size: 1rem;
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking-text {
  animation: blink 1s 2;

}
.custom-tolltip {
  display: flex;
  flex-wrap: wrap;
  max-width: 200px; /* Set the initial max-width as needed */
}

@media (max-width: 768px) {
  .custom-tolltip {
    max-width: 80vw; /* Adjust the value as needed */
  }
}

