.logo {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #12C28A;
}

.header {
    font-weight: 400;
    font-size: 50px;
    align-items: center;
    text-align: center;
    color: #0D697D;
}

.challenge {
    padding: 0px 150px 0px;
}
.text-center {
    text-align: center;
}
.top_header {
    font-weight: 700;
    background-color: rgb(13, 105, 125);
    text-align: center;
    padding: 35px 10px;
    background-size: contain;
    background-position: inherit;
    background-repeat: no-repeat;
    background-position-x: 46px;
    background-image: url('../../public/assets/images/Grafiitigrafiiti.svg');
}

.top_header p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0px;
}

.top_header span {
    font-size: 10px;
}

.sub_title {
    color: #fff;
    font-size: 14px !important;
}

.second_header {
    background: linear-gradient(105.18deg, #ccf4fc -2.04%, #eaffd5 107.42%);
    background-size: cover;
    display: flex;
    padding: 0 150px 1px;
    width: -webkit-fill-available;
}

.logo img {
    width: 70px;
    height: 70px;
}

.menu ul {
    padding: 0px;
    margin: 0px;
    display: flex;
}

.menu ul>li {
    list-style-type: none;
    color: #40DA4B;
    text-transform: uppercase;
    padding: 15px 10px;
    font-size: 13px;
    font-weight: 600;
    margin: 14px 0px;
}

.menu ul>li:last-child {
    padding: 7px 10px;
}

.menu ul>li button {
    border: 2px solid #40DA4B;
    border-radius: 20px;
    background-color: transparent;
    color: #40DA4B;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    padding: 7px 25px;
}

.content_header {
    color: #0D697D;
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 30px;
    margin-top: 70px;
    white-space: nowrap;
}

.content_sub_header {
    font-size: 19px;
    margin-bottom: 21px;
    color: #0d697d;
}


.nav_header {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px 0px 222px 0px;
    padding: 0px 150px 200px;
    position: relative;
}

.globe_img {
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-position-x: -128px;
    height: 115%;
    width: 100%;
}

.mob_menu_popup_body {
    position: fixed;
    top: 0px;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 20%);
    z-index: 999;
    animation: FadeIn 1s 1;
    animation-delay: 0.8;
}

@keyframes FadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.mob_menu_popup_content {
    width: 300px;
    height: 100%;
    left: 0px;
    top: -2px;
    position: absolute;
    box-shadow: #0000005c 0px 10px 7px 0.5px;
    background-color: #ffffff;
    border: 1px solid #f3f3f3;
}

.mob_menu_popup_content .menus ul {
    padding: 0px;
}

.mob_menu_popup_content .menus ul li {
    list-style-type: none;
    padding: 10px 20px;
    text-align: left;
    font-size: 12px;
    border-bottom: 1px solid #E5FDDC;
    color: #0d697d;
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;
}

.mob_menu_popup_content .menus ul li a {
    color: unset;
    text-decoration: unset;
}

.mob_menu_popup_content .menus ul li i {
    font-size: 22px;
    width: 40px;
    border-radius: 35px;
    margin: 0px 9px 2px 0px;
    color: #0d697d;
    padding: 14px 2px;
    text-align: center;
}

.mob_header,
.mob_header,
.mob_banner {
    display: none;
}

.mobile_menu {
    display: block;
    line-height: 70px;
    margin: 29px 21px;
    cursor: pointer;
    position: absolute;
    top: -7px;
    right: 141px;
    z-index: 999;
}

.mobile_menu i {
    background-color: #C8F1E4;
    padding: 15px 15px;
    border-radius: 34px;
    cursor: pointer;
    color: #726969;
    box-shadow: #a4a1a12b 0px 5px 2px;
    animation: FadeIn 1s 1;
    animation-delay: 0.8;
}

.mob_header .mobile_menu img {
    height: 23px;
    width: 23px;
}

.mob_header {
    font-size: 40px;
    color: #525151;
    padding: 3px 145px;
    text-align: center;
    cursor: pointer;
    margin: 0px 5px 0px 0px;
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    z-index: 999;
    transition-duration: 2s;
    transition-timing-function: linear;
    transition-delay: 1s;
}

.mob_header {
    display: flex;
    background: linear-gradient(105.18deg, #CCF4FC -2.04%, #EAFFD5 107.42%);
}

.mob_header .flex_grow-1 {
    flex-grow: 1;
}

.mob_header .Logo img {
    width: -moz-fit-content;
    width: 60px;
    height: 60px;
}

.mob_header .Logo {
    display: flex;
}

.mob_header .Logo p {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #12C28A;
    margin: 20px 10px;
    text-transform: uppercase;
}

/* ============= responsive start =============  */
@media only screen and (min-width: 300px) and (max-width: 480px) {
    .content_header {
        font-size: 15px !important;
    }

    .happy_customers {
        padding-bottom: 80px !important;
    }
}

@media only screen and (min-width: 350px) and (max-width: 590px) {
    .top_header {
        background-image: unset !important;
        display: none;
    }
}

@media only screen and (min-width: 590px) and (max-width: 767px) {
    .top_header {
        background-image: url('/public/assets/images/65navheader_bg.png'), url('/public/assets/images/66navheader_bg.png') !important;
        background-position: right bottom, left top;
        background-repeat: no-repeat;
        background-size: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .mob_header {
        padding: 0px;
    }

    .mob_banner {
        display: block;
    }

    .mob_hide,
    .web_banner {
        display: none;
    }

    .challenge {
        padding: 0px 0px 0px;
        text-align: center;
    }

    .carsole_img {
        height: unset !important;
    }

    .nav_header {
        padding: 0px 0px 60px;
        border-radius: 0px 0px 142px 0px;
    }

    .menu {
        display: none;
    }

    .flex_grow-1 {
        flex-grow: unset;
    }

    .content_header {
        font-size: 25px;
        line-height: 44px;
        margin: 25px 0px 0px 0px;
        font-weight: bold;
    }

    .globe_img {
        background-size: 109%;
        background-position: -86px;
    }

    .content_sub_header {
        font-size: 13px;
    }

    .second_header {
        padding: 0 15px 1px;
    }

    .mobile_menu {
        top: 0px;
        right: 0px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1280px) {
    .nav_header {
        padding: 0px 0px 50px 0px
    }

    .second_header {
        padding: 0px 100px;
    }

    .menu ul {
        margin-top: 14px;
    }

    .center {
        left: 15%;
    }

    .second_header {
        background-size: inherit;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .globe_img {
        background-size: 107%;
        background-position-x: -49px;
    }

    .mob_banner {
        display: block;
    }

    .mob_hide,
    .web_banner {
        display: none;
    }
}

.addToHomeScreen {
    width: fit-content;
    margin: auto;
}

.addToHomeScreen img {
    margin: 10px;
    width: 30px;
    height: 30px;
}
.android-store-icon,
.apple-store-icon {
    width: 60px;
    margin: 2px auto;
}