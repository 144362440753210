.flipcard{
    background: rgba(0,0,0,0.1);
    display: block;
  }
  
  .flipcard-content{
    width: 100%;
    height: 100%;
    transition: transform 750ms;
    transform-style: preserve-3d;
    transform-origin: center right;
    position: relative;
    display: block;
  }
  
  .flipcard-content-front,
  .flipcard-content-back{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: block;
  }
  
  .flipcard-content-back{
    transform: rotateY(180deg);
  }
  
  /* a little hacky, but sets the size of the front/back, regardless the element type */
  .flipcard-content-front > *,
  .flipcard-content-back > *{
    width: 100%;
    height: 100%;
  }
  
  /* the checkbox shouldn't be visible */
  .flipcard-checkbox{
    position: fixed;
    top: -1000px;
    left: -1000px;
  }
  
  .flipcard.flipcard-click{
    cursor: pointer;
  }
  
  .flipcard.flipcard-hover:hover > .flipcard-content,
  .flipcard-checkbox:checked + .flipcard-content{
    transform: translateX(-100%) rotateY(-180deg);
  }