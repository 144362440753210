
  /* input[type="submit"]{
    color: #0d697d !important;
    border-color: #0d697d !important;
  }
  input[type="submit"]:hover,
  input[type="submit"]:focus {
    color: #ffffff !important;
    background-color: #0d697d !important;
    outline: none !important;
    box-shadow: 0 4px 6px 0 rgba(22, 22, 26, 0.18) !important;
  } */

  .signin-body {
    height: calc(100vh - 360px);
  }

  .linkedIn {
    background-color: #0A61B9 !important;
    color: white !important;
  }

  .linkedIn:hover {
    background-color: #034281 !important;
  }